import { clientFilesResult } from 'types/clientFileDtos';
import { formatDateFr } from 'utils/DateUtils';
import { CodeLibelle } from 'types';
import { useEffect, useState } from 'react';
import ContractPaper from 'icons/ContractPaper';
import StarIcon from 'icons/StarIcon';
import { formatDisplayedFirstname, truncateWithDots } from 'utils/commun';
import {
  StyledResultCard,
  StyledResultCardData,
  StyledResultCardLabel,
  StyledResultCardTile,
  StyledResultCardTreatmentTag,
  StyledResultCardWithInsuranceTag,
  StyledResultCardWithoutInsuranceTag,
} from './style';
import * as messages from './messages';

interface ResultCardProps {
  file: clientFilesResult;
  niveauxProcess?: CodeLibelle[];
  onClick: (numeroProjet: number, projetDetail: clientFilesResult) => void;
  inDashboard?: boolean;
  delai?: boolean;
}

const ResultCard: React.FC<ResultCardProps> = ({
  file,
  niveauxProcess,
  onClick,
  inDashboard,
  delai,
}) => {
  const [niveauProcessLabel, setNiveauProcessLabel] = useState<string>('');
  const [cardBackgroundColor, setCardBackgroundColor] = useState<string>('#FFFFFF');

  const listTraitementAllege = ['7', '8', '9', 'A', 'B', 'C', 'E'];
  const listTraitementSimple = ['1', '4', 'D'];

  const isGreen = listTraitementAllege
    .concat(listTraitementSimple)
    .includes(file.ProcessusDeTraitement);

  useEffect(() => {
    setNiveauProcessLabel(
      niveauxProcess
        ?.filter(niveauProcess => niveauProcess.code !== '')
        .find(niveauProcess => {
          return niveauProcess.code === file.ProcessusDeTraitement;
        })?.libelle || '',
    );
  }, [niveauxProcess]);

  return (
    <>
      <StyledResultCard
        onMouseEnter={() => {
          setCardBackgroundColor('#F1FBFE');
        }}
        onMouseLeave={() => {
          setCardBackgroundColor('#FFFFFF');
        }}
        color={cardBackgroundColor}
        onClick={() => {
          onClick(file.IdProjet, file);
        }}>
        <div
          style={{
            display: 'flex',
            textAlign: 'start',
          }}>
          <StyledResultCardTile style={{ width: '10.7rem' }}>
            <StyledResultCardLabel>
              {messages.RESULT_CARD_FILE_NUMBER}
            </StyledResultCardLabel>
            <StyledResultCardData>{file.IdProjet}</StyledResultCardData>
          </StyledResultCardTile>
          <StyledResultCardTile style={{ width: '11.4rem' }}>
            <StyledResultCardLabel>
              {messages.RESULT_CARD_LAST_NAME}
            </StyledResultCardLabel>
            <StyledResultCardData>{truncateWithDots(file.Nom, 8)}</StyledResultCardData>
          </StyledResultCardTile>
          <StyledResultCardTile style={{ width: inDashboard ? '12.4rem' : '13.2rem' }}>
            <StyledResultCardLabel>
              {messages.RESULT_CARD_FIRST_NAME}
            </StyledResultCardLabel>
            <StyledResultCardData>
              {formatDisplayedFirstname(truncateWithDots(file.Prenom, 12))}
            </StyledResultCardData>
          </StyledResultCardTile>
          {!inDashboard && (
            <StyledResultCardTile style={{ width: '13.2rem' }}>
              <StyledResultCardLabel>
                {messages.RESULT_CARD_CREATION_DATE}
              </StyledResultCardLabel>
              <StyledResultCardData>
                {formatDateFr(file.DateCreation)}
              </StyledResultCardData>
            </StyledResultCardTile>
          )}
          {/* traitement ou date delai retractation  */}
          <div style={{ width: '21.3rem' }}>
            {delai ? (
              <StyledResultCardTile>
                <StyledResultCardLabel>
                  {messages.RESULT_CARD_DELAI_DATE}
                </StyledResultCardLabel>
                <StyledResultCardData>
                  {formatDateFr(file.DateCreation)}
                </StyledResultCardData>{' '}
              </StyledResultCardTile>
            ) : (
              <>
                {niveauProcessLabel !== '' && (
                  <StyledResultCardTreatmentTag
                    isGreen={isGreen}
                    style={{ position: 'relative' }}>
                    <StyledResultCardData>{niveauProcessLabel}</StyledResultCardData>
                    {listTraitementAllege.includes(file.ProcessusDeTraitement) && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '-1rem',
                          right: '-0.5rem',
                          gap: '0.1rem',
                        }}>
                        <StarIcon />
                        <StarIcon />
                      </div>
                    )}
                  </StyledResultCardTreatmentTag>
                )}
                {file.PackEditEbroInd === 'O' && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '0.7rem',
                      alignItems: 'center',
                    }}>
                    <div style={{ marginTop: '0.5rem' }}>
                      <ContractPaper color="#000" width={16} />
                    </div>
                    <span
                      style={{
                        color: '#4B4F54',
                        fontFamily: 'Open Sans',
                        fontSize: '12px',
                      }}>
                      Pack initial édité par le partenaire
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* avec ou sans garantie */}
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {file.GarantieIndicateur === 'O' ? (
            <StyledResultCardWithInsuranceTag>
              <StyledResultCardLabel>
                {messages.RESULT_CARD_WITH_INSURANCE}
              </StyledResultCardLabel>
            </StyledResultCardWithInsuranceTag>
          ) : (
            <StyledResultCardWithoutInsuranceTag>
              <StyledResultCardLabel>
                {messages.RESULT_CARD_WITHOUT_INSURANCE}
              </StyledResultCardLabel>
            </StyledResultCardWithoutInsuranceTag>
          )}
        </div>
      </StyledResultCard>
    </>
  );
};

export default ResultCard;
