import Space from 'components/space/Space';
import BaseButton from 'components/designSystem/BaseButton';
import WaitingFilesCard from 'components/waitingFilesCard/WaitingFilesCard';
import Loader from 'components/Loader';
import theme from 'styles/theme';
import { StyledButtonLabel } from 'containers/search/style';
import FolderSearchIcon from 'icons/FolderSearchIcon';
import FileIcon from 'icons/FileIcon';
import LookingGlassIcon from 'icons/LookingGlassIcon';
import ChevronRight from 'icons/ChevronRight';
import ClockIcon2 from 'icons/ClockIcon2';
import { formatDateFr, getPastDayFromToday } from 'utils/DateUtils';
import { isVip } from 'containers/communs/utils';
import { CodeLibelle, StatusInfoDashboard } from 'types';
import { clientFilesResult } from 'types/clientFileDtos';
import { NavigateFunction, useNavigate } from 'react-router';
import { getListeDossierDashboard } from 'api/clientFileService';
import { getReferential } from 'api/referentialService';
import {
  cleanFileSessionStorageData,
  userCanChangeApa,
  userHasSelectedApa,
} from 'utils/commun';
import { getExistingClientFile } from 'utils/mapping/mapping';
import TextArea from 'components/textArea/TextArea';
import NoActionFilesCard from 'components/noActionFilesCard/NoActionFilesCard';
import { useEffect, useState } from 'react';
// import listeDossierMock from 'mocks/listDossierDashboard.json';
import { StyledButtonRowContainer, StyledDashboardMainTitle } from './style';
import * as messages from './messages';

const renderRechercheBordereauMainButton = (navigate: NavigateFunction) => {
  return (
    <BaseButton
      onClick={() => {
        navigate('/bordereau');
      }}
      style={{
        background: theme.colors.chartColors.mainGreenBnp500,
        display: 'flex',
        alignItems: 'center',
      }}>
      <FolderSearchIcon color="#FFFFFF" />
      <StyledButtonLabel style={{ marginLeft: '1rem', marginRight: '1.6rem' }}>
        {messages.DASHBOARD_RECHERCHE_BORDEREAUX_BUTTON_LABEL}
      </StyledButtonLabel>
      <ChevronRight color="#FFF" />
    </BaseButton>
  );
};

const renderCreateNewFileMainButton = (navigate: NavigateFunction) => {
  return !userCanChangeApa() || userHasSelectedApa() ? (
    <BaseButton
      onClick={() => {
        cleanFileSessionStorageData();
        navigate('/donnees_client');
      }}
      style={{
        background: theme.colors.chartColors.mainGreenBnp500,
        display: 'flex',
        alignItems: 'center',
      }}>
      <FileIcon color="#FFFFFF" />
      <StyledButtonLabel style={{ marginLeft: '1rem' }}>
        {messages.DASHBOARD_CREATE_NEW_FILE_BUTTON_LABEL}
      </StyledButtonLabel>
    </BaseButton>
  ) : (
    <BaseButton
      disabled
      style={{
        background: '#b1b5b9',
        display: 'flex',
        alignItems: 'center',
      }}>
      <FileIcon color="#FFFFFF" />
      <StyledButtonLabel style={{ marginLeft: '1rem' }}>
        {messages.DASHBOARD_CREATE_NEW_FILE_BUTTON_LABEL}
      </StyledButtonLabel>
    </BaseButton>
  );
};

const renderCreateNewFileButton = (
  style: 'main' | 'secondary',
  navigate: NavigateFunction,
) => {
  return style === 'main' ? (
    renderCreateNewFileMainButton(navigate)
  ) : (
    <BaseButton
      onClick={() => {
        navigate('/donnees_client');
      }}
      style={{
        background: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
      }}>
      <FileIcon color={theme.colors.chartColors.mainGreenBnp500} />
      <StyledButtonLabel
        style={{ marginLeft: '1rem', color: theme.colors.chartColors.mainGreenBnp500 }}>
        {messages.DASHBOARD_CREATE_NEW_FILE_BUTTON_LABEL}
      </StyledButtonLabel>
    </BaseButton>
  );
};

const renderAccessFileSearchSecondaryButton = (navigate: NavigateFunction) => {
  return !userCanChangeApa() || userHasSelectedApa() ? (
    <BaseButton
      onClick={() => {
        navigate('/recherche');
      }}
      style={{
        background: '#FFF',
        display: 'flex',
        alignItems: 'center',
      }}>
      <LookingGlassIcon color={theme.colors.chartColors.mainGreenBnp500} />
      <StyledButtonLabel
        style={{
          marginLeft: '1rem',
          marginRight: '1.6rem',
          color: theme.colors.chartColors.mainGreenBnp500,
        }}>
        {messages.DASHBOARD_ACCESS_SEARCH_BUTTON_LABEL}
      </StyledButtonLabel>
      <ChevronRight color={theme.colors.chartColors.mainGreenBnp500} />
    </BaseButton>
  ) : (
    <BaseButton
      disabled
      style={{
        background: '#b1b5b9',
        display: 'flex',
        alignItems: 'center',
      }}>
      <LookingGlassIcon color="white" />
      <StyledButtonLabel
        style={{
          marginLeft: '1rem',
          marginRight: '1.6rem',
          color: 'white',
        }}>
        {messages.DASHBOARD_ACCESS_SEARCH_BUTTON_LABEL}
      </StyledButtonLabel>
      <ChevronRight color="white" />
    </BaseButton>
  );
};

const renderAccessFileSearchButton = (
  style: 'secondary' | 'text',
  navigate: NavigateFunction,
) => {
  return style === 'secondary' ? (
    renderAccessFileSearchSecondaryButton(navigate)
  ) : (
    <BaseButton
      onClick={() => {
        navigate('/recherche');
      }}
      style={{
        background: '#FFF',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
      }}>
      <LookingGlassIcon color={theme.colors.chartColors.mainGreenBnp500} />
      <StyledButtonLabel
        style={{
          marginLeft: '1rem',
          marginRight: '1.6rem',
          color: theme.colors.chartColors.mainGreenBnp500,
        }}>
        {messages.DASHBOARD_ACCESS_SEARCH_BUTTON_LABEL}
      </StyledButtonLabel>
      <ChevronRight color={theme.colors.chartColors.mainGreenBnp500} />
    </BaseButton>
  );
};

const DashboardForm: React.FC = () => {
  const navigate = useNavigate();

  const [displayWarning, setDisplayWarning] = useState<boolean>(false);
  const [niveauxProcess, setNiveauxProcess] = useState<CodeLibelle[]>([]);
  const [listeDossier, setlisteDossier] = useState<clientFilesResult[]>([]);
  const [hasError, setHasError] = useState<string>('');
  const [listStatusInfoBefore, setListStatusInfoBefore] = useState<StatusInfoDashboard[]>(
    [],
  );
  const [listStatusInfoAfter, setListStatusInfoAfter] = useState<StatusInfoDashboard[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const NBR_DAY_FROM_TODAY = 60;

  useEffect(() => {
    if (userCanChangeApa() && !userHasSelectedApa()) setDisplayWarning(true);
    else {
      getListeDossierDashboard(NBR_DAY_FROM_TODAY).then(result => {
        if (result && result.Success)
          setlisteDossier(
            result.Resultat.Dossiers.filter(dossier => dossier.StatutMaitre !== 'E'),
          );
        else {
          let errorMsg =
            "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.";
          if (result.ListeMessages && result.ListeMessages.length > 0) {
            errorMsg = result.ListeMessages[0].MessageLib;
            setHasError(errorMsg);
          }
        }
      });
    }

    // appel list mock pour les test
    // setlisteDossier(listeDossierMock.Resultat.Dossiers as unknown as clientFilesResult[]);

    getReferential('niveauProcess').then(result => {
      if (result && result.Success) setNiveauxProcess(result.Resultat);
    });
    sessionStorage.removeItem('listeDossier');
  }, [sessionStorage.getItem('selectedApa')]);

  useEffect(() => {
    const initListStatueInfoBefore: StatusInfoDashboard[] = [
      {
        label: 'Transféré à l’octroi',
        id: 'H',
        listeDossier: listeDossier.filter(dossier => dossier.StatutMaitre === 'H'),
      },
      {
        label: 'Incomplet non décidable / non étudiable',
        id: 'OB',
        listeDossier: listeDossier.filter(
          dossier => dossier.StatutMaitre === 'O' || dossier.StatutMaitre === 'B',
        ),
      },
      {
        label: 'Avis favorable compléments',
        id: 'j',
        listeDossier: listeDossier.filter(dossier => dossier.StatutMaitre === 'J'),
      },
    ];

    const initListStatueInfoAfter: StatusInfoDashboard[] = [
      {
        label: 'Offre de prêt AG éditée',
        id: '1',
        listeDossier: listeDossier.filter(dossier => dossier.StatutMaitre === '1'),
      },
      {
        label: 'Pack contractuel SG édité',
        id: 'K',
        listeDossier: listeDossier.filter(dossier => dossier.StatutMaitre === 'K'),
      },
      {
        label: 'Financement en cours',
        id: 'S',
        listeDossier: listeDossier.filter(
          dossier => dossier.StatutPropCial === 'R' || dossier.StatutPropCial === '2',
        ),
      },
    ];

    setListStatusInfoBefore(initListStatueInfoBefore);
    setListStatusInfoAfter(initListStatueInfoAfter);
  }, [listeDossier]);

  const getFileDetailsAndNavigate = (
    numeroProjet: number,
    projetDetail: clientFilesResult,
  ) => {
    setIsLoading(true);

    getExistingClientFile(numeroProjet, projetDetail)
      .then(() => {
        navigate('/consultation');
      })
      .catch(error => {
        console.error(error);
        alert(
          "Une erreur technique s'est produite. Merci de réessayer ulterieurement ou de contacter votre administrateur.",
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeWarning = () => {
    setDisplayWarning(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {isLoading && <Loader animationDuration={0.9} />}
      <Space marginTop="6.4rem" />
      <StyledDashboardMainTitle>{messages.DASHBOARD_MAIN_TITLE}</StyledDashboardMainTitle>

      {displayWarning && !userHasSelectedApa() && (
        <>
          <Space marginBottom="3.2rem" />
          <div style={{ width: 'fit-content' }}>
            <TextArea
              type="warning"
              title="Avant de commencer !"
              includeIcon
              onClose={closeWarning}>
              <span
                style={{ color: '#4B4F54', fontFamily: 'Open Sans', fontSize: '1.4rem' }}>
                Vous devez obligatoirement choisir dans la rubrique{' '}
                <span style={{ fontWeight: 'bold' }}>« Mon compte »</span> l’agence de
                votre groupement pour laquelle vous souhaitez intervenir.
              </span>
            </TextArea>
          </div>
        </>
      )}

      <Space marginTop="5.6rem" />

      {isVip() ? (
        <StyledButtonRowContainer>
          {renderRechercheBordereauMainButton(navigate)}
          {renderCreateNewFileButton('secondary', navigate)}
          {renderAccessFileSearchButton('text', navigate)}
        </StyledButtonRowContainer>
      ) : (
        <StyledButtonRowContainer>
          {renderCreateNewFileButton('main', navigate)}
          {renderAccessFileSearchButton('secondary', navigate)}
        </StyledButtonRowContainer>
      )}

      <Space marginTop="5.6rem" />

      {hasError ? (
        <>
          <Space marginBottom="3.2rem" />
          <TextArea type="error" title="une error est survenue" includeIcon>
            <span
              style={{ color: '#4B4F54', fontFamily: 'Open Sans', fontSize: '1.4rem' }}>
              {hasError}
            </span>
          </TextArea>
        </>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2.4rem' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              fontSize: '14px',
            }}>
            <ClockIcon2 color="4B4F54" />
            {`Affichage des dossiers créés depuis le ${formatDateFr(
              getPastDayFromToday(NBR_DAY_FROM_TODAY),
            )} `}
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', gap: '3rem' }}>
            <NoActionFilesCard
              listeDossier={listeDossier.filter(dossier => dossier.StatutMaitre === 'W')}
              mainColor="#F5F1F9"
              barColor="#8F0D96"
              label="Reçu, en attente de prise en charge"
              niveauxProcess={niveauxProcess}
              getFileDetailsAndNavigate={getFileDetailsAndNavigate}
            />
            <NoActionFilesCard
              listeDossier={listeDossier.filter(dossier => dossier.StatutMaitre === 'L')}
              mainColor="#F1FBFE"
              barColor="#0066BF"
              label="Pris en charge à l’octroi"
              niveauxProcess={niveauxProcess}
              getFileDetailsAndNavigate={getFileDetailsAndNavigate}
            />
            <NoActionFilesCard
              listeDossier={listeDossier.filter(dossier => dossier.StatutMaitre === 'G')}
              mainColor="#F2F3F3"
              barColor="#6F757C"
              label="Refus octroi"
              niveauxProcess={niveauxProcess}
              getFileDetailsAndNavigate={getFileDetailsAndNavigate}
            />
            <NoActionFilesCard
              listeDossier={listeDossier.filter(
                dossier => dossier.StatutMaitre === 'toto',
              )}
              mainColor="#F2F9F1"
              barColor="#008859"
              label="Délai de rétractation en cours"
              niveauxProcess={niveauxProcess}
              getFileDetailsAndNavigate={getFileDetailsAndNavigate}
              delai
            />
            <NoActionFilesCard
              listeDossier={listeDossier.filter(dossier => dossier.StatutMaitre === '3')}
              mainColor="#F2F9F1"
              barColor="#008859"
              label="Financé"
              niveauxProcess={niveauxProcess}
              getFileDetailsAndNavigate={getFileDetailsAndNavigate}
              last
            />
          </div>

          <Space marginBottom="1.6rem" />

          <div style={{ display: 'flex', flexDirection: 'row', gap: '3.2rem' }}>
            <WaitingFilesCard
              type="avant"
              niveauxProcess={niveauxProcess}
              getFileDetailsAndNavigate={getFileDetailsAndNavigate}
              listStatusInfo={listStatusInfoBefore}
            />
            <WaitingFilesCard
              type="après"
              niveauxProcess={niveauxProcess}
              getFileDetailsAndNavigate={getFileDetailsAndNavigate}
              listStatusInfo={listStatusInfoAfter}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardForm;
